import React from "react";
import {CheckBox, Label} from "@material-ui/icons";
import {Checkbox, FormLabel, Button} from "@material-ui/core";
import history from "../utils/history";

export default function TermsAndConditions(props) {

    return (
        <div onUpdate={() => window.scrollTo(0, 0)} className="terms-body">
            <Button onClick={()=> history.replace('/home')}>&lt; Back</Button><br/><br/>
            <h1>General Terms and Conditions</h1><br/>
            <h2>The reader will hereinafter be referred to as "the user"</h2><br/>
            <h2>HEC and its creators will hereinafter be referred to as "the system" and "the host" respectively</h2><br/>
            <h2>Use</h2><br/><br/>
            <p>This site has been built for the pleasure and convenience it affords its users:</p><br/>
            <p>By agreeing to these terms,
                I agree to conduct myself as a responsible human being and an adult while interacting with other users
                in the context of the system.</p><br/>
                <p>I agree that as a blog management and content hosting system/provider the system will orchestrate
                    a fair exchange of thoughts and content amongst users as is reasonable in the context of a normal
                    blog management and content hosting system.</p><br/>
            <p>The host does so with pleasure, but will not be held responsible for the following:</p><br/>
            <ul>
                <li>One user misrepresenting themself to another</li>
                <li>Misrepresentation of content on the part of users to each other</li>
            </ul>
            <br/>
            <p>
                I agree to take responsibility for the content I create and my exchanges with other users of the system.
            </p>
            <br/><br/>
            <p>
                I understand that the host expects a level of conduct.
            </p><br/>
            <p>
                I understand that the host reserves the right to remove me as a user of the system if my conduct is not
                acceptable as per the terms and agreement.
            </p>
        </div>
    )
}
