import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import axios from "axios";
import Button from "@material-ui/core/Button";
import history from "../utils/history";
import Posts from "../blog/posts";
import {Badge, Box, Menu, MenuItem, withStyles} from "@material-ui/core";
import NotificationsIcon from '@material-ui/icons/Notifications';
import AssignmentIcon from '@material-ui/icons/Assignment';
import {AccountCircle} from "@material-ui/icons";
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import SetNotificationsCounter from "../functional/setNotificationCounter";
import {Link} from "react-router-dom";

const drawerWidth = 240;

const StyledButton = withStyles({
    root: {
        borderRadius: 3,
        border: 1,
        borderColor: 'lightsteelblue',
        borderStyle: 'solid',
        color: 'lightsteelblue',
        height: 48,
        padding: '0 30px',
    },
    label: {
        textTransform: 'capitalize',
    },
})(Button);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1
    },
    title: {
      flexGrow: 1
    },
    footerStyles: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        width: `calc(100% - ${drawerWidth}px)`
    }
}));

function ResponsiveDrawer(props) {

    console.log(props)

    const { window } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isMenuOpen = Boolean(anchorEl);

    const dateNow = new Date();
    const [myDate, setDate] = React.useState(dateNow.getFullYear());

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const logout = () =>{
        axios.post('//api.isogramconsulting.com/api/post/logout', {}, {mode: "cors", credentials: 'include'}).then((res) => {
            // console.log(res.data);
            if(res.data.loggedOut){
                history.replace('/');
            }
        }).catch((err) => console.log(err))
    }

    const drawer = (
        <div>
            <div className={classes.toolbar} />
            <SetNotificationsCounter />
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={()=>history.replace('/settings')}><SettingsIcon/><div className="menu-space"> Account </div> </MenuItem>
            <MenuItem onClick={()=>history.replace('/profile')}><PersonIcon/><div className="menu-space"> Profile </div> </MenuItem>
            <MenuItem onClick={logout}><ExitToAppIcon /> <div className="menu-space"> Logout </div> </MenuItem>
        </Menu>
    );

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>

                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        All Posts
                    </Typography>
                        <IconButton
                            className={classes.iconButton}
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                </Toolbar>
            </AppBar>
            {renderMenu}
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Posts />
                <div className={classes.footerStyles}>
                    <Box p={2}><Link to='/termsandconditions'>
                        Terms And Conditions
                    </Link></Box>
                    <Box p={2}>
                        <div className="sticker">&copy; Isogram Consulting {myDate}</div>
                    </Box>
                </div>
            </main>
        </div>
    );
}

ResponsiveDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default ResponsiveDrawer;
