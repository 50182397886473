import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as ACTIONS from '../store/actions/actions';
import { Link } from "react-router-dom";
import history from '../utils/history';
import axios from "axios";

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import ResponsiveDrawer from "./ResponsiveDrawer";

import '../App.css';
import {SwipeableDrawer} from "@material-ui/core";
import Profile from "./profile";
import AvatarHeader from "../functional/avatarHeader";
import {setAvatar} from "../store/dataObjectManagement/setAvatar";

class Settings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            post_id: null,
            currentUser: [],
        }

        this.getUserData();

    }

    componentDidMount() {

    }

    getUserData = () => {

        axios.get('//api.isogramconsulting.com/api/get/userprofile', {mode: "cors", credentials: 'include'})
            .then((res) => {
                // console.log(res.data[0]);

                let myavatar = setAvatar(res.data);

                this.setState({
                    currentUser: {
                        uid: res.data[0].uid,
                        username: res.data[0].username,
                        friend_user_id: res.data[0].friend_user_id,
                        friends: res.data[0].friends,
                        email_verified: res.data[0].email_verified,
                        image: myavatar
                    }
                })

            }).catch((err) => console.log(err))

    }

    EmailVerified = (props) => (
        <div>
            <Card className="emailVerified">
                <h6> Email Verified: </h6>
                {props.props.email_verified ? <p>Yes</p> : <p>No</p> }
            </Card>
        </div>
    )

    handleClickOpen = (pid) => {
        this.setState({ open: true, post_id: pid })
    }

    handleClose = () => {
        this.setState({ open: false, post_id: null })
    }




    RenderPosts = post => (
        <Card style={{width: '500px', height: '200px', marginBottom: '10px', paddingBottom: '80px'}}>
            <CardHeader
                title={<Link to={{ pathname: '/post/'}}>
                   Title
                </Link>}
                subheader={
                    <div className="FlexColumn">
                        <div className="FlexRow">
                            Some dunk
                        </div>
                        <div className="FlexRow">
                            <Link to={{pathname: '/editpost/'}}>
                                <button onClick={()=>console.log('Hello')}>Edit</button>
                            </Link>
                            <button onClick={() => this.handleClose()}>Delete</button>
                        </div>
                    </div>
                }/>
                <br/>
                <CardContent>
                    <span style={{overflow: 'hidden'}}>
                        alskjdfo;hsauihfiusdhiufhsiufhdiuah
                    </span>
                </CardContent>
        </Card>
    );



  render() {
    return(
      <div>
          <AvatarHeader props={this.state}/>
          <this.EmailVerified props={this.state}/>
      </div>

    )}
}


function mapStateToProps(state) {
  return {
    profile: state.auth_reducer.profile,
    user_posts: state.posts_reducer.user_posts,
    db_profile: state.auth_reducer.db_profile
  }
}

function mapDispatchToProps (dispatch){
    return{
        set_user_posts: (posts) => dispatch(ACTIONS.fetch_user_posts(posts))
    }
}

export default connect(mapStateToProps, null)(Settings);
