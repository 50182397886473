import React, {Component} from "react";
import Button from "@material-ui/core/Button";
import history from "../utils/history";
import Avatar from "@material-ui/core/Avatar";
import {connect} from "react-redux";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {Badge} from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ListItemText from "@material-ui/core/ListItemText";
import AssignmentIcon from "@material-ui/icons/Assignment";
import axios from "axios";

export default function SetNotificationsCounter (props) {

    const [counter, updateCounter] = React.useState(0);

    React.useEffect(function effectFunction() {
        getData().then(data => {
            console.log(data);
            updateCounter(data);
        }).catch(err => {
            console.log(err)
        })
    })

    const getData = async () => {
        try {
            let data = [];
            let result1 = await axios.get("//api.isogramconsulting.com/api/get/userprofile", {withCredentials:true})
            let result2 = await axios.get("//api.isogramconsulting.com/api/get/getNotifications", {withCredentials: true, params: {user_id: result1?.data[0]?.uid}})
            return data = result2?.data?.size[0]?.size;
        } catch (error) {
            console.error(error)
        }
    }

    return(
        <div>
            <Divider />
            <List>
                <ListItem button onClick={()=>history.replace('/profile')} aria-label="show activity notifications">
                    <ListItemIcon><Badge badgeContent={counter} color="secondary"><NotificationsIcon /></Badge></ListItemIcon>
                    <ListItemText primary="Notifications" />
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem button onClick={()=>history.replace('/addpost')} aria-label="show 4 activity notifications">
                    <ListItemIcon><AssignmentIcon /></ListItemIcon>
                    <ListItemText primary="Add Post" />
                </ListItem>
            </List>
            <Divider />
        </div>
    )
}
