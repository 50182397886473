import React, {Component} from 'react';
import { connect } from 'react-redux';

import Settings from './containers/settings';
import LoginPage from './containers/loginPage';
import Signup from './containers/Signup';
import ResponsiveDrawer from "./containers/ResponsiveDrawer";
import ResponsiveWithCollapse from "./containers/ResponsiveWithCollapse"

import Posts from './blog/posts';
import AddPost from './blog/addpost';
import ShowPost from './blog/showpost';
import EditPost from './blog/editpost';

import Home from './functional/loginSignup';

import * as ACTIONS from './store/actions/actions';

import Auth from './utils/auth';
import history from './utils/history';

import { Router, Route, Switch, Redirect } from 'react-router';
import ResetPassword from "./functional/resetPassword";
import Profile from "./containers/profile";
import TermsAndConditions from "./functional/TermsAndConditions";
import verifyEmail from "./functional/verifyEmail";

export const auth = new Auth()

const handleAuthentication = (props) => {
  if(props.location.hash) {
    auth.handleAuth()
  }
}

const PrivateRoute = ({component: Component, auth }) => (
  <Route render={props => auth.isAuthenticated() === true
    ? <Component auth={auth} {...props} />
    : <Redirect to={{pathname:'/redirect'}} />
  }
  />
)



class Routes extends Component {
  componentDidMount() {
  }

  render() {
    return(
      <div>
        <Router history={history} >
        <div>
          <Switch>
            <Route exact path='/' component={ResponsiveWithCollapse} />
            <Route exact path='/home' component={ResponsiveWithCollapse} />

            <Route path='/posts' component={ResponsiveDrawer} />
            <Route path='/addpost' component={AddPost} />

            <Route path='/post/edit/:id' component={EditPost} />
            <Route path='/post/view/:id' component={ShowPost} />

            <Route path="/profile" component={Profile}/>
            <Route path="/settings" component={Settings}/>
            <Route path="/login" component={LoginPage}/>
            <Route path="/signup" component={Signup}/>
            <Route path="/reset/:id" component={ResetPassword}/>
            <Route path="/verify/:id" component={verifyEmail}/>
            <Route path="/termsandconditions" component={TermsAndConditions}/>

          </Switch>
        </div>
        </Router>
      </div>
    )}
}


function mapDispatchToProps (dispatch) {
  return {
    login_success: () => dispatch(ACTIONS.login_success()),
    login_failure: () => dispatch(ACTIONS.login_failure()),
    add_profile: (profile) => dispatch(ACTIONS.add_profile(profile)),
    remove_profile: () => dispatch(ACTIONS.remove_profile())
  }
}


export default connect(null, mapDispatchToProps)(Routes);
