import React from 'react'
import "../App.css"
import {Link} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import {Box} from "@material-ui/core";

export default function Footer (props) {

    const dateNow = new Date();
    const [myDate, setDate] = React.useState(dateNow.getFullYear());

    return (
        <div className="footer-styles">
            <Box p={2}><Link to='/termsandconditions'>
                Terms And Conditions
            </Link></Box>
            <Box p={2}>
                <div className="sticker">&copy; Isogram Consulting {myDate}</div>
            </Box>
        </div>
    )
}
