import React, {Component, useImperativeHandle} from 'react';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';

import * as ACTIONS from '../store/actions/actions';

import history from '../utils/history';
import {Card, CardMedia, CardActionArea, TextField, Button, Paper} from '@material-ui/core/';
import {connect} from 'react-redux';
import Comments from "./comments"
import imageY from '../images/randompostimage1.jpg';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Collapse from "@material-ui/core/Collapse";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddComment from "./addcomment";
import ShareIcon from "@material-ui/icons/Share";
import {CardActions} from "./showpost";
import {setVimeo} from "../store/dataObjectManagement/setVideo";
import {setAvatar} from "../store/dataObjectManagement/setAvatar";
import {setProfileSplash} from "../store/dataObjectManagement/setProfileSplash";
import Footer from "../functional/footer";
import {formatDate} from "../utils/formatDate";

class ShowPost extends Component {

    state = { open: false, expanded: false, editMode: false, parentLocation:
            {id: this.props.location.pathname.split('/')[3]}, post: {title: "", body: "", username: "", uid: 0, pid: 0, image: "", video: ""} };

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    getCurrentPostData = async() => {
        try{
            let result = await axios.get('//api.isogramconsulting.com/api/get/getpostbypostid', {mode: "cors",
                credentials: 'include', params: {post_id: this.state.parentLocation.id}});
            return result;
        }catch (e) {
            console.error(e);
        }
    }


    getCurrentPost(){
        console.log("id", this.props.location.pathname, this.state.parentLocation.id);


            this.getCurrentPostData().then((res) => {

                let videolink = setVimeo(res.data)
                let random = setAvatar(res.data)
                let profileSplash = setProfileSplash(res.data)
                let ndate = formatDate(res.data[0]);

                this.setState({ post: {
                        title: res.data[0].title,
                        body: res.data[0].body,
                        username: res.data[0].author,
                        uid: res.data[0].user_id,
                        pid: res.data[0].pid,
                        date: ndate,
                        image: profileSplash,
                        image_a: random,
                        video: videolink
                }})

                console.log(this.state);

            }).catch((err) => {
                console.log(err);
                console.log(err.response);
                if(err.response.status===401 || err.response.status===403){
                    window.location.href = '/';
                }
            })

    }


    componentDidMount() {

        this.getCurrentPost();

    }

    handleChange = (e) => {
        const { name, value } = e.target;
        if(name==='title'){
            this.setState(prevState => ({
                post: {
                    ...prevState.post,
                    title: value
                }
            }))
        }
        if(name==='body'){
            this.setState(prevState => ({
                post: {
                    ...prevState.post,
                    body: value
                }
            }))
        }

    };


    handleSubmit = (event) => {
        event.preventDefault()

        const data = this.state.post;

        axios.post('//api.isogramconsulting.com/api/post/updatepost', data, {mode: "cors",
            credentials: 'include'})
            .then(response => console.log(response))
            .catch((err) => {
                console.log(err);
                if(err.response.status===401 || err.response.status===403){
                    window.location.href = '/';
                }
            }).finally(()=> history.replace('/posts/'))
    }


    deletePostWithComments = () => {
        const foo = this.state.post;

        const headers = {
            'Authorization': 'Bearer x'
        }

        axios.all([axios.delete('//api.isogramconsulting.com/api/delete/postcomments', {headers, mode: "cors", credentials: 'include', params: {pid: foo.pid}}),
                        axios.delete('//api.isogramconsulting.com/api/delete/post', {headers, mode: "cors", credentials: 'include', params: {pid: foo.pid}})])
            .then( axios.spread((firstResponse, secondResponse) =>{
            console.log(firstResponse.data, secondResponse.data)
        })).catch((err) => {
            console.log(err);
            if(err.response.status===401 || err.response.status===403){
                window.location.href = '/';
            }
        }).finally(()=> history.replace('/posts/'))
    }

    handleClose = () => {
        this.setState(prevState => ({
            ...prevState,
            open: false,
            post: {
                ...prevState.post
            }
        }))
    }

    handleExpandClick = () => {
        console.log(this.state);
        this.state.expanded = !this.state.expanded;
    };

    RenderVideo =(link)=>(
        <div className="video-frame">
            <iframe src={this.state.post.video} width="640" height="352" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen>
            </iframe>
        </div>
    );

    render(){
        return(
            <div style={{padding: '20px'}}>
                <Button onClick={()=> history.replace('/posts/')}>&lt; Back</Button>
                <Button onClick={(e) => this.setState({expanded: !this.state.expanded})}>Edit</Button>
                <br/><br/>
                <Card>
                    <CardHeader
                        avatar={
                            <Avatar alt="Chosen avatar" src={this.state.post.image_a} />
                        }
                        action={
                            <IconButton aria-label="settings">
                                <MoreVertIcon />
                            </IconButton>
                        }
                        title={this.state.post.title + " by " + this.state.post.username}
                        subheader={this.state.post.date}
                    />
                    <div>{(this.state.post.video==="no_video")?(<CardMedia
                            style={{width: '100%', height: '300px'}}
                            image={(this.state.post.image)?(this.state.post.image):imageY}
                            title={'The is the post Image'} />)
                        :<this.RenderVideo />}</div>
                    <form id="post" name="post" style={{padding: '20px'}} onSubmit={this.handleSubmit}>
                        <IconButton aria-label="share">
                            <ShareIcon />
                        </IconButton>
                        <TextField
                            variant="outlined"
                            name='title'
                            multiline
                            rows='1'
                            margin='normal'
                            onChange={this.handleChange}
                            defaultValue={this.state.post.title}
                            fullWidth
                        />
                        <br/>
                        <TextField
                            variant="outlined"
                            name='body'
                            multiline
                            rows='4'
                            margin='normal'
                            onChange={this.handleChange}
                            defaultValue={this.state.post.body}
                            fullWidth
                        />
                        <br/>
                        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                            <Button type='submit'>Submit</Button>
                            <Button onClick={() => this.setState({open: true})}> Delete</Button>
                            <Button onClick={() => this.getCurrentPost}>Cancel</Button>
                        </Collapse>
                    </form>

                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                        aria-labelledby='alert-dialog-title'
                        aria-describedby='alert-dialog-description'

                    >
                        <DialogTitle id='alert-dialog-title'> Confirm Delete? </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <strong>Deleting Post</strong> <br/>
                                If you agree, your post will be gone forever
                            </DialogContentText>
                                <DialogActions>
                                    <Button onClick={() => {this.deletePostWithComments(); this.setState({open: false})}}>
                                        Confirm
                                    </Button>
                                    <Button onClick={() => this.setState({open: false})}>
                                        Cancel
                                    </Button>
                                </DialogActions>
                        </DialogContent>
                    </Dialog>
                    <AddComment post={this.state.post} parentpath={this.state.parentLocation} />
                    <Comments post={this.state.post} parentpath={this.state.parentLocation}/>
                </Card>
                <Footer/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        profile: state.auth_reducer.profile,
        db_profile: state.auth_reducer.db_profile,
        is_authenticated: state.auth_reducer.is_authenticated,
        post: state.post
    }
}

function mapDispatchToProps(dispatch){
    return{
        is_authenticated: (is_authenticated) => dispatch(ACTIONS.login_success(is_authenticated))
    }
}


export default connect(mapStateToProps, null)(ShowPost);
