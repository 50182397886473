import React, {Component} from 'react';
import {connect} from 'react-redux';

import * as ACTIONS from '../store/actions/actions';
import history from '../utils/history';
import axios from "axios";

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader'
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import {setAvatar} from "../store/dataObjectManagement/setAvatar";
import AvatarHeader from "../functional/avatarHeader"
import '../App.css';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText, Snackbar,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {blueGrey, green, purple, red} from "@material-ui/core/colors";
import {Person, PersonAdd} from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";

class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            post_id: null,
            currentUser: [],
            friendRequests: [],
            usersInFR: [],
            success: false,
            acceptedBool: false,
            temp_rowId: 0,
            temp_item: []
        }
    }

    componentDidMount() {

        this.getData().then(data => {

            // console.log(data);

            let newdataobj = {}
            let newdataall = []

            if (data.usersInFR !== undefined) {

                // console.log(data.usersInFR);

                data.usersInFR.map((user, index) => {
                    // console.log(user);
                    let new_friend = false;
                    let temp = [];
                    temp.push(user);

                    let in_author_friends = user.friend_user_id?.includes(data.userprofile[0].uid)
                    let in_currentuser_friend = data.userprofile[0].friend_user_id.includes(user.uid)

                    if (in_author_friends && in_currentuser_friend) {
                        new_friend = true;
                    }

                    // console.log(in_author_friends, in_currentuser_friend, new_friend);

                    let myfriendavatar = setAvatar(temp);

                    newdataobj = {
                        rowId: index,
                        uid: user.uid,
                        username: user.username,
                        image: myfriendavatar,
                        new_friend: new_friend
                    }

                    newdataall.push(newdataobj);
                    // console.log(newdataall);
                })
            }

            let myavatar = setAvatar(data.userprofile);

            this.setState({
                currentUser: {
                    uid: data.userprofile[0].uid,
                    username: data.userprofile[0].username,
                    friend_user_id: data.userprofile[0].friend_user_id,
                    friends: data.userprofile[0].friends,
                    image: myavatar
                },
                friendRequestArray: data.friendRequests,
                friendRequests: newdataall
            })
        }).catch(err => {
            console.log(err)
        });
    }

    getData = async () => {
        // SQL JOIN in tables?... prove me wrong aka deep
        try {
            let data = [];
            let result3 = [];
            let result1 = await axios.get("//api.isogramconsulting.com/api/get/userprofile", {mode: "cors", credentials: 'include'})
            let result2 = await axios.get("//api.isogramconsulting.com/api/get/friendRequests", {mode: "cors", credentials: 'include', params: {user_id: result1.data[0].uid}})
            // console.log(result2.data[0]?.friend_request_id);
            if (result2.data[0]?.friend_request_id !== undefined && result2.data[0]?.friend_request_id !== null) {
                result3 = await axios.get("//api.isogramconsulting.com/api/get/allUsersInFriendReq", {mode: "cors", credentials: 'include', params: {user_ids: result2.data[0]?.friend_request_id}})
            }
            return data = {userprofile: result1.data, friendRequests: result2.data, usersInFR: result3?.data}
        } catch (error) {
            console.error(error)
        }
    }

    handleClickOpen = (pid) => {
        this.setState({open: true, post_id: pid})
    }

    handleClose = () => {
        this.setState({open: false, post_id: null})
    }


    addFriendToDB = async (data) => {
        let resultdata = []
        let result = await axios.put('//api.isogramconsulting.com/api/put/friends/', data, {mode: "cors", credentials: 'include'})
            .then(!this.state.currentUser?.friend_user_id?.includes(data.post_user_id) && this.state.acceptedBool
                ? this.setState({currentUser: {friends: this.state.currentUser.friends + 1}}) : null).catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.status === 403) {
                    window.location.href = '/';
                }
            })
        let result1 = await axios.put('//api.isogramconsulting.com/api/put/completeFriendRequest/', data, {mode: "cors", credentials: 'include'}).catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.status === 403) {
                    window.location.href = '/';
                }
            })
        return result.data;
    }


    search = (mValue) => {
        var newItemArr = [];
        var newItem = {}

        this.state.friendRequests.map(item => {
            if (item.uid === mValue) {

                // console.log("rowId:", item.rowId,
                    //"uid:", item.uid,
                    //"username:", item.username,
                   // "image:", item.image);


                var newItem = {
                    rowId: item.rowId,
                    uid: item.uid,
                    username: item.username,
                    image: item.image,
                    new_friend: true
                }

                newItemArr.push(newItem);
            }
        });
        // console.log("newItemArray", newItemArr);
        return newItemArr;
    }

    updateItem(newItemArray) {
        var index = newItemArray[0].rowId;

        const newFriends = [...this.state.friendRequests];
        newFriends.splice(index, 1, newItemArray[0])

        this.setState(prevState => ({
            ...prevState,
            friendRequests: newFriends
        }))
    }

    RefreshUI(user) {
        let postuserid = user
        var newItemArray = this.search(postuserid, this.state.friendRequests);
        // console.log("newItemArray", newItemArray);
        this.updateItem(newItemArray);
    }


    addFriend = (user) => {
        // console.log(user);

        let userid = this.state.currentUser.uid
        let postuserid = user

        const data = {uid: userid, post_user_id: postuserid}

        // console.log(data.uid + " adding friend" + postuserid)


        this.addFriendToDB(data).then(result => {

            // console.log(result);
            // console.log("success", result?.success[0]?.uid);
            if (result?.success[0]?.uid === this.state.currentUser.uid) {

                this.RefreshUI(postuserid);
                this.setState({success: true});
            }
        })

    }

    handleToastClose = () => {
        this.setState({success: false});
    };

    RenderFriendRequests = (props) => (
        <ListItem>
            <ListItemAvatar>
                <Avatar alt="Chosen avatar" src={props.props.image}/>
            </ListItemAvatar>
            <ListItemText primary={props.props.username} secondary={'Sent you a friend request'}/>
            <ListItemSecondaryAction>
                <div className="render-actions">
                    <div>
                        {props.props.new_friend ? (<div><IconButton aria-label="you are friends">
                            <Person style={{color: purple[500]}}/>
                        </IconButton></div>) : (<div><IconButton onClick={() => this.addFriend(props.props.uid)}
                                                                 aria-label="add new friend">
                            <PersonAdd style={{color: blueGrey[500]}}/>
                        </IconButton></div>)}
                    </div>
                </div>
            </ListItemSecondaryAction>
        </ListItem>
    )


    render() {
        return (
            <div className="profileWrapper">
                <Snackbar open={this.state.success} autoHideDuration={6000} onClose={this.handleToastClose}>
                    <Alert onClose={this.handleToastClose} severity="success">
                        Success! You have a new friend
                    </Alert>
                </Snackbar>

                <AvatarHeader props={this.state}/>

                <Card className="notificationsCard">
                    <CardHeader
                        title="Friend Requests"
                    />
                    <CardContent className="cardContent">
                        <div>{(Object.keys(this.state.friendRequests).length === 0) && (
                            <div>You have no new friend requests</div>)}</div>
                        <List className="myprofileul" style={{overflowY: 'scroll'}}>
                            {((this.state.friendRequests !== []) && this.state?.friendRequests) ? this.state.friendRequests.map((el, i) => {
                                return (<this.RenderFriendRequests key={i} props={el}/>)
                            }) : null}
                        </List>
                    </CardContent>
                </Card>

            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        profile: state.auth_reducer.profile,
        user_posts: state.posts_reducer.user_posts,
        db_profile: state.auth_reducer.db_profile
    }
}

function mapDispatchToProps(dispatch) {
    return {
        set_user_posts: (posts) => dispatch(ACTIONS.fetch_user_posts(posts))
    }
}

export default connect(mapStateToProps, null)(Profile);
