//import auth0 from 'auth0-js'
import history from './history';
import axios from "axios";

export default class Auth {
  /*auth0 = new auth0.WebAuth({
    domain: 'webapp1.auth0.com',
    clientID: 'uZxUdMAsiDWeu3OrNpoi4JwJscdF5nAx',
    redirectUri: 'http://localhost:3000/callback',
    responseType: 'token id_token',
    scope: 'openid profile email'
  })*/

  userProfile = {}

  login = (username, password) => {
    let loginData = {}

    axios.post('//api.isogramconsulting.com/api/post/login', {params: {username: username, password: password}}, {mode: "cors", credentials: 'include'})
        .then((res) => loginData = res.data)
        .catch((err) => console.log(err))

    this.handleAuth(loginData);
  }

  handleAuth = (loginData) => {
    //this.auth0.parseHash((err, authResult) => {
    if(loginData) {
      let expiresAt = JSON.stringify((new Date().getTime()))
      localStorage.setItem('expiresAt', expiresAt)

      //this.getProfile();
      setTimeout(() => {
        history.replace('/authcheck')
      }, 2000);

    }//})
  }

  getAccessToken = () => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${"token"}=`);
    if(parts.length === 2) {
      return parts.pop().split(';').shift();
    } else {
      return null
    }
  }


  /*getProfile = () => {
    let accessToken = this.getAccessToken()
    if(accessToken) {
      this.auth0.client.userInfo(accessToken, (err, profile) => {
          if(profile) {
            this.userProfile = { profile }
          }
      } )
    }
  }*/


  logout = () => {
    /*localStorage.removeItem('access_token')
    localStorage.removeItem('id_token')
    localStorage.removeItem('expiresAt')*/
    setTimeout(() => { history.replace('/authcheck') }, 200);
  }

  isAuthenticated = () => {
    let expiresAt = JSON.parse(localStorage.getItem('expiresAt'))
    return new Date().getTime() < expiresAt
  }

}
