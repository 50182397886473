import React from 'react';
import { connect } from 'react-redux';
import * as ACTIONS from '../store/actions/actions';
import history from '../utils/history';
import {Box, Button, Snackbar, TextField} from '@material-ui/core';
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import {Link} from "react-router-dom";
import {useParams} from "react-router";


class ResetPassword extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            password: "",
            repeatpassword: "",
            submitted: false,
            token: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
        // console.log(this.props.match.params.id);
        if(this.props?.match.params.id){
            this.setState({
                token: this.props.match.params.id
            })
        }

    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { token, password, repeatpassword } = this.state;

        if ( token && password && repeatpassword && repeatpassword===password) {

            axios.post('//api.isogramconsulting.com/api/post/changePassword', {mode: "cors", credentials: 'include', params: {token: token, password: repeatpassword}})
                .then((res) => {
                    if(res.data.success){
                        this.setState({
                            open: true
                        })
                    }
                }).catch((err) => {
                console.log(err)
                console.log(err.response)
            })
        }
    }


    handleToastClose = () => {
        this.setState({
            open: false
        })
        history.replace('/login');
    };

    render() {
        const { password, repeatpassword, submitted} = this.state;

        return (
            <div>
                <Snackbar open={this.state.open} autoHideDuration={6000} onClose={this.handleToastClose}>
                    <Alert onClose={this.handleToastClose} severity="success">
                        Your password has successfully been reset
                    </Alert>
                </Snackbar>
                <Box m={1} style={{padding: '5px'}}>
                    <h2>Reset Password</h2>
                    <form onSubmit={this.handleSubmit} autoComplete="off">
                        <div style={{display: "inline-flex", flexDirection: 'column', margin: '5px 0'}}>
                            <div>
                                <TextField name="password" label="Password" variant="outlined" onChange={this.handleChange} type="password"/>
                                {submitted && !password &&
                                    <div className="help-block">Password is required</div>
                                }
                            </div>
                            <div style={{marginTop: '10px', marginBottom: '5px'}}>
                                <TextField name="repeatpassword" label="Confirm Password" variant="outlined" onChange={this.handleChange} type="password"/>
                                {submitted && !repeatpassword &&
                                    <div className="help-block">Confirm change by entering new password again</div>
                                }
                            </div>
                            {(repeatpassword!==password) &&
                                <div className="help-block">Password and confirmed password must match</div>
                            }
                        </div>
                        <br/>
                        <div>
                            <Button type="submit" variant="contained">Submit</Button>
                        </div>
                    </form>
                </Box>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        profile: state.auth_reducer.profile,
        db_profile: state.auth_reducer.db_profile,
        is_authenticated: state.auth_reducer.is_authenticated
    }
}

function mapDispatchToProps(dispatch){
    return{
        is_authenticated: (is_authenticated) => dispatch(ACTIONS.login_success(is_authenticated))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
