import React, {Component, useEffect, useImperativeHandle} from 'react';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';

import * as ACTIONS from '../store/actions/actions';

import history from '../utils/history';
import {Card, CardMedia, CardActions, TextField, Button, Paper} from '@material-ui/core/';
import {connect} from 'react-redux';
import Comments from "./comments"
import imageY from "../images/randompostimage4.jpg";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import CardContent from "@material-ui/core/CardContent";
import AddComment from "./addcomment";
import {Badge, Menu, MenuItem, Snackbar, Tab, Tabs, withStyles} from "@material-ui/core";
import {Person, PersonAdd} from "@material-ui/icons";
import {blue, deepPurple, green, grey, red} from "@material-ui/core/colors";
import {setProfileSplash} from "../store/dataObjectManagement/setProfileSplash";
import {setAvatar} from "../store/dataObjectManagement/setAvatar";
import {setVimeo} from "../store/dataObjectManagement/setVideo";
import Alert from "@material-ui/lab/Alert";
import Footer from "../functional/footer";
import {formatDate} from "../utils/formatDate";

const styles = theme => ({
    root: {
        color: blue
    },
    mySelectedA: {
        color: red[500]
    },
    mySelectedB: {
        color: deepPurple[500]
    },
});

class ShowPost extends Component {

    state = { waiting: false, success: false, new_friend: 0, like_post: false, open: false, openComment: false, parentLocation: {id: this.props.location.pathname.split('/')[3]},
        post: {title: "", body: "", username: "", uid: 0, pid: 0, date: "", image: "", video: ""},
        currentUser: []};


    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }


    getCurrentPost = async() => {

        // console.log("id", this.props.location.pathname, this.state.parentLocation.id);

        try{
            let data = [];
            let result1 = await axios.get('//api.isogramconsulting.com/api/get/getpostbypostid', {withCredentials: true, params: {post_id: this.state.parentLocation.id}}).catch((err) => {
                console.log(err);
                if(err.response.status===401 || err.response.status===403){
                    window.location.href = '/login';
                }});
            let result2 = await axios.get('//api.isogramconsulting.com/api/get/userprofile', {withCredentials:true}).catch((err) => {
                    console.log(err);
                    if(err.response.status===401 || err.response.status===403){
                        window.location.href = '/login';
                }});

            data = {post: result1.data, userprofile: result2.data};
            return data;
        } catch (error){
            console.error(error)
        }

    }


    componentDidMount() {

        this.getCurrentPost().then(data =>{
            let new_friend = false;
            let videolink = setVimeo(data.post)
            let random = setAvatar(data.post)
            let profileSplash = setProfileSplash(data.post)
            let ndate = formatDate(data.post[0]);

            // console.log('likeusers', data.post[0].like_user_id);
            let like_post = data.post[0].like_user_id.includes(data.userprofile[0].uid)
            // console.log('likesobj', like_post);

            let in_author_friends = data.post[0].friend_user_id.includes(data.userprofile[0].uid)
            let in_currentuser_friend = data.userprofile[0].friend_user_id.includes(data.post[0].user_id)

            if(in_author_friends && in_currentuser_friend){
                new_friend = true;
            }
            // console.log('friendobj', new_friend);

            this.setState({ post: {
                    title: data.post[0].title,
                    body: data.post[0].body,
                    username: data.post[0].author,
                    uid: data.post[0].user_id,
                    pid: data.post[0].pid,
                    likes: data.post[0].likes,
                    like_user_id: data.post[0].like_user_id,
                    image: profileSplash,
                    image_a: random,
                    video: videolink,
                    date: ndate
                },
                currentUser: data.userprofile[0],
                new_friend: new_friend,
                like_post: like_post,
            })
        });
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        if(name==='title'){
            this.setState(prevState => ({
                post: {
                    ...prevState.post,
                    title: value
                }
            }))
        }
        if(name==='body'){
            this.setState(prevState => ({
                post: {
                    ...prevState.post,
                    body: value
                }
            }))
        }

    };

    likePost = () => {

        // console.log(this.state.currentUser)
        let userid = this.state.currentUser.uid
        let post_id = this.state.post.pid
        // console.log(userid, post_id);

        const data = {uid: userid, post_id: post_id}

        this.putLike(data).then(result=>{
            // console.log(result[0].like_user_id);

            if(result[0].like_user_id.includes(userid)){
                this.setState(prevState => ({
                        ...prevState,
                        like_post: true,
                        post: {
                            ...prevState.post,
                            likes: this.state.post.likes + 1
                        }
                }))
                // console.log(this.state);
            }
        })
    }

    putLike = async(data) => {
        try{
            let result1 = await axios.put('//api.isogramconsulting.com/api/put/likes', data, {withCredentials:true})
                .then(!this.state.post.like_user_id?.includes(data.uid) && this.state.like_post
                    ? this.setState({likes: this.state.post.likes + 1 } ) : null)
                .catch((err) => {
                    console.log(err);
                    if(err.response.status===401 || err.response.status===403){
                        window.location.href = '/';
                    }})
            return result1.data;
        }catch (e) {
            console.log(e)
        }
    }


    reloadOrNavigate = () => {
        const { history, location } = this.props;
        const curLocation = '/post/view/' + this.state.parentLocation.id
        const locationId = this.state.parentLocation.id;
        if (location.pathname === (curLocation)) {
            history.replace(`/reload`);
            setTimeout(() => {
                history.replace(`/post/view/${locationId}`);
            });
        } else {
            history.push('/post/view/' + locationId);
        }
    };


    handleClose = () => {
        this.setState(prevState => ({
            ...prevState,
            open: false,
            post: {
                ...prevState.post
            }
        }))
    }


    RenderVideo = (link) =>(
        <div className="video-frame">
            <iframe src={this.state.post.video} width="640" height="352" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen>
            </iframe>
        </div>
    );

    ShareToFriends = () => {

        // console.log('this is a test');

        const url = document.querySelector('link[rel=canonical]') ? document.querySelector('link[rel=canonical]').href : document.location.href;

        console.log(url);

    }

    SendFriendRequest = () => {
        console.log(this.state.currentUser.username, " is adding friend")

        this.addFriendDBAndSendRequestQuery().then(data =>{

            // console.log("success", data.putFriend?.success[0]?.uid, data.sendRequest[0]?.friend_request_complete);

            if((data.putFriend?.success[0]?.uid===this.state.currentUser.uid)
                &&data.sendRequest[0]?.friend_request_id.includes(this.state.currentUser.uid)){

                this.setState(prevState => ({
                        ...prevState,
                        success: true,
                        waiting: true,
                }))

            }else if((data.putFriend?.success[0]?.uid===undefined)
                &&data.sendRequest[0]?.friend_request_id===undefined){

                this.setState(prevState => ({
                    ...prevState,
                    success: true,
                    waiting: true,
                }))
            }
        });
    }


    addFriendDBAndSendRequestQuery = async() => {

        console.log(this.state.currentUser, " is adding friend")
        let userid1 = this.state.currentUser.uid
        let postuserid1 = this.state.post.uid

        const data1 = {uid: userid1, post_user_id: postuserid1}

        let userid2 = this.state.currentUser.uid
        let postuserid2 = this.state.post.uid

        const data2 = {uid: userid2, post_user_id: postuserid2}

        let newdata = []

        try{
            let result1 = await axios.put('//api.isogramconsulting.com/api/put/friends/', data1, {withCredentials: true})
                .then(!this.state.currentUser?.friend_user_id?.includes(postuserid1) && this.state.new_friend
                    ? this.setState({ currentUser: { friends: this.state.currentUser.friends + 1 }}) : null)
                .catch((err) => {
                    console.log(err);
                    if(err.response.status===401 || err.response.status===403){
                    window.location.href = '/';
                }})
            let result2 = await axios.post('//api.isogramconsulting.com/api/post/friendrequest', data2, {withCredentials: true}).catch((err) => {
                console.log(err);
                if(err.response.status===401 || err.response.status===403){
                    window.location.href = '/';
                }})

            // console.log("testing for result", result1.data, result2.data);

            return newdata = {putFriend: result1.data, sendRequest: result2.data}

        }catch(e) {
            console.log(e);
        }
    }

    handleToastClose = () => {
        this.setState({ success: false });
    };


    render(){

        const { classes, theme } = this.props;

        return(
            <div style={{padding: '20px'}} className={classes.root}>
                <Snackbar open={this.state.success} autoHideDuration={6000} onClose={this.handleToastClose}>
                    <Alert onClose={this.handleToastClose} severity="success">
                        Your friend request has been sent to {this.state.post.username}
                    </Alert>
                </Snackbar>

                <Button onClick={()=> history.replace('/posts/')}>&lt; Back</Button><br/><br/>
                <Card>
                    <CardHeader
                        avatar={
                            <Avatar alt="Chosen avatar" src={this.state.post.image_a} />
                        }
                        action={
                            <IconButton aria-label="settings">
                                <MoreVertIcon />
                            </IconButton>
                        }
                        title={this.state.post.title + " by " + this.state.post.username}
                        subheader={this.state.post.date}
                    />
                    <div>{(this.state.post.video==="no_video")?(<CardMedia
                            style={{width: '100%', height: '300px'}}
                            image={(this.state.post.image)?(this.state.post.image):imageY}
                            title={'The is the post Image'} />)
                        :<this.RenderVideo />}</div>
                    <CardActions disableSpacing>
                        <div>{this.state.new_friend?(<div>
                            <IconButton aria-label="this person is on your friend list" disabled={true}>
                            <Person className={classes.mySelectedB}/>
                            </IconButton></div>)
                            :(this.state.waiting?(<div className="plusters"><Badge badgeContent={"+"} color="secondary"><Person /></Badge></div>)
                                :(<div>
                                    <IconButton onClick={this.SendFriendRequest} aria-label="add this person to your friend list to get updates on their activity">
                                        <PersonAdd />
                                    </IconButton>
                                </div>))}
                        </div>
                        <div>
                            {this.state.like_post?(<div aria-label="you liked this">
                                    <FavoriteIcon className={classes.mySelectedA}/>
                                </div>)
                                :(<div><IconButton onClick={this.likePost} aria-label="if you liked what you saw, heart this">
                                    <FavoriteIcon />
                                </IconButton>
                                </div>)}
                        </div>
                        <div className="notification-num-posts">{this.state.post.likes}</div>
                    </CardActions>
                    <CardContent>
                        <TextField
                            variant='outlined'
                            name='body'
                            multiline
                            rows='4'
                            margin='normal'
                            defaultValue={this.state.post.body}
                            fullWidth
                            disabled={true}
                        />
                        <br/>
                    </CardContent>
                    <AddComment post={this.state.post} parentpath={this.state.parentLocation} />
                    <Comments post={this.state.post} parentpath={this.state.parentLocation}/>
                </Card>
                <Footer/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        profile: state.auth_reducer.profile,
        db_profile: state.auth_reducer.db_profile,
        is_authenticated: state.auth_reducer.is_authenticated,
        post: state.post
    }
}

function mapDispatchToProps(dispatch){
    return{
        is_authenticated: (is_authenticated) => dispatch(ACTIONS.login_success(is_authenticated))
    }
}


export default connect(mapStateToProps, null)(withStyles(styles, { withTheme: true })(ShowPost));


