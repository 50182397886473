import React, { Component } from 'react';
import axios from 'axios';

import * as ACTIONS from '../store/actions/actions';

import {TextField, Button, Paper} from '@material-ui/core/';
import {connect} from 'react-redux';
import {List, ListItem, ListItemText, ListItemAvatar, ListItemSecondaryAction} from "@material-ui/core";
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/Delete';
import {green} from "@material-ui/core/colors";
import AddCommentIcon from '@material-ui/icons/AddComment';
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@material-ui/icons/Edit';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {setAvatar} from "../store/dataObjectManagement/setAvatar";
import {formatDate} from "../utils/formatDate";

class Comments extends Component {

    data = []

    state = {
        post: {},
        data: this.data,
        handleEmpty: false,
        parentPath: this.props.parentpath.id,
        currentUser: [],
        open: false,
        openDelete: false,
        currently_logged: "",
        temp_comment: "",
        temp_id: 0
    };

    constructor(props) {
        super(props);

        this.getPostComments();
        this.handleChange = this.handleChange.bind(this);
    }

    PostCommentsFromDB = async() => {
        let id = this.state.parentPath;
        try{
            const [comment, currentUser] = await axios.all([axios.get('//api.isogramconsulting.com/api/get/allcomments', {mode: "cors", credentials: 'include', params: {post_id: id}}),
                axios.get('//api.isogramconsulting.com/api/get/userprofile', {mode: "cors", credentials: 'include'})]).catch((err) => {
                    console.log(err);
                    if(err.response.status===401 || err.response.status===403){
                        window.location.href = '/';
                    }
                })
            let result = [comment, currentUser];
            return result;
        }catch (e) {
            console.log(e);
        }
    }

    getPostComments() {

        let comments = []
        let currentuser = 0;

        this.PostCommentsFromDB().then(result=>{

            comments = result[0].data;
            currentuser = result[1].data[0].uid;

            let data = [];
            let avatar = "";
            let tempobj = [];

            for (let i in comments) {

                let ndate = formatDate(comments[i]);
                tempobj.push(comments[i]);
                avatar = setAvatar(tempobj);

                let comment = {
                    cid: comments[i].cid,
                    comment: comments[i].comment,
                    author: comments[i].author,
                    user_id: comments[i].user_id,
                    date_created: ndate.toString(),
                    image: avatar,
                    currently: currentuser
                };
                data.push(comment);
            }

            this.setState({
                data: data
            })
        })


    }

    search = (mValue, myArray)=>{
        myArray.map(item => {
            if(item.cid===mValue){
                return this.state.temp_comment = item.comment;
            }
        });
    }

    editComment = (id) => {
        this.search(id, this.state.data);
        this.state.temp_id = id;
        this.setState({open: true})
    }

    submitToDB = async() => {
        let data = {cid: this.state.temp_id, comment: this.state.temp_comment}
        try{
           let result = await axios.put('//api.isogramconsulting.com/api/put/updatecomment', data, {mode: "cors", credentials: 'include'}).catch((err) => {
               console.log(err);
               if(err.response.status===401 || err.response.status===403){
                   window.location.href = '/';
               }
           })
           return result;
        }catch (e) {
            console.log(e)
        }
    }

    submitEditComment = () => {
        this.submitToDB().then(result=>{
            this.getPostComments();
        })
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({[name]: value})
    }

    deleteComment = (id) => {
        this.setState({temp_id: id, openDelete: true});
    }

    submitDeleteToDB = async() =>{
        try{
            let result = await axios.delete('//api.isogramconsulting.com/api/delete/comment', {headers: {'Authorization': 'Bearer x'
            }, mode: "cors", credentials: 'include', params: {cid: this.state.temp_id}}).catch((err) => { console.log(err);
                if(err.response.status===401 || err.response.status===403){
                    window.location.href = '/';
                }})
            return result;
        } catch (e) {
          console.log(e)
        }
    }

    submitDeleteComment = () => {
        this.submitDeleteToDB().then(result=>{
            this.getPostComments();
        });
    }

    replyToComment = (e) => {
        //TODO: the comment appears as a nested reply to a comment
        console.log('test');
    }

    RenderComments = (props) => (
        <ListItem >
            <ListItemAvatar>
                <Avatar alt="Chosen avatar" src={props.props.image} />
            </ListItemAvatar>
            <ListItemText primary={props.props.comment} secondary={props.props.author + ' commented on '+ props.props.date_created} />
            <ListItemSecondaryAction>
                <div className="render-actions">
                    {(props.props.user_id===props.props.currently)?(<div><IconButton onClick={()=>this.editComment(props.props.cid)} aria-label="edit this comment"><EditIcon style={{ color: green[500] }}/></IconButton>
                        <IconButton onClick={()=>this.deleteComment(props.props.cid)} aria-label="delete this comment"><DeleteIcon style={{ color: green[500] }}/></IconButton></div>):null}
                    <IconButton onClick={()=>this.replyToComment()} aria-label="reply to this comment" disabled={true}><AddCommentIcon style={{ color: green[500] }}/></IconButton>
                </div>
            </ListItemSecondaryAction>
        </ListItem>
    )

    handleClose = () => {
        this.setState(prevState => ({
            ...prevState,
            open: false,
        }))
    }

    handleCloseDelete = () => {
        this.setState(prevState => ({
            ...prevState,
            openDelete: false,
        }))
    }

    render(){

        return(

            <div style={{padding: '20px'}}>
                <h1>{Object.keys(this.state.data).length} Comments</h1>
                    <List className="myul">
                        { this.state.data ? this.state.data.map((el, i) => {
                            return ( <this.RenderComments key={i} props={el}/> ) }): null }
                    </List>
                 {(Object.keys(this.state.data).length === 0) && <div className="help-block">
                    Be the first to comment </div>}

                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby='edit-dialog-title'
                    aria-describedby='edit-dialog-description'

                >
                    <DialogTitle id='edit-dialog-title'> Edit Comment </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="edit-dialog-description">
                            <strong>Select submit to edit your comment</strong> <br/>
                        </DialogContentText>
                        <TextField
                            variant="outlined"
                            name='temp_comment'
                            multiline
                            rows='4'
                            margin='normal'
                            defaultValue={this.state.temp_comment}
                            onChange={this.handleChange}
                            fullWidth
                        />
                        <DialogActions>
                            <Button onClick={() => {this.submitEditComment(); this.setState({open: false})}}>
                                Confirm
                            </Button>
                            <Button onClick={() => this.setState({open: false})}>
                                Cancel
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>


                <Dialog
                    open={this.state.openDelete}
                    onClose={this.handleCloseDelete}
                    aria-labelledby='delete-dialog-title'
                    aria-describedby='delete-dialog-description'

                >
                    <DialogTitle id='delete-dialog-title'> Delete Comment? </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="delete-dialog-description">
                            <strong>If you select confirm your comment will be gone forever</strong> <br/>
                        </DialogContentText>
                        <DialogActions>
                            <Button onClick={() => {this.submitDeleteComment(); this.setState({openDelete: false})}}>
                                Confirm
                            </Button>
                            <Button onClick={() => this.setState({openDelete: false})}>
                                Cancel
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        profile: state.auth_reducer.profile,
        db_profile: state.auth_reducer.db_profile,
        is_authenticated: state.auth_reducer.is_authenticated,
        comments: state.comments,
        post: state.post,
        currentUser: state.currentUser
    }
}

function mapDispatchToProps(dispatch){
    return{
        is_authenticated: (is_authenticated) => dispatch(ACTIONS.login_success(is_authenticated))
    }
}


export default connect(mapStateToProps, null)(Comments);
