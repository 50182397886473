export function setVimeo(data){

    let link="no_video";

    if(data[0].video!==null&&data[0].video!==undefined){
        link = "https://player.vimeo.com/video/"+data[0].video;
    }

    return link;
}
