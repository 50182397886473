import React from 'react';
import { connect } from 'react-redux';
import * as ACTIONS from '../store/actions/actions';
import history from '../utils/history';
import {Box, Button, Checkbox, FormLabel, Snackbar, TextField} from '@material-ui/core';
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import {Link} from "react-router-dom";

class ResetPassword extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            checked: false,
            open: false,
            submitted: false,
            token: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
        // console.log(this.props.match.params.id);
        if(this.props?.match.params.id){
            this.setState({
                token: this.props.match.params.id.split('--')[0]
            })
        }

    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { token, checked } = this.state;

        if ( token && checked ) {

            console.log(token);
            axios.post('//api.isogramconsulting.com/api/post/verifyaccount', {mode: "cors", credentials: 'include', params: {token: token, terms_accepted: checked}})
                .then((res) => {
                    if(res.data.success){
                        this.setState({
                            open: true
                        })
                    }
                }).catch((err) => {
                console.log(err)
                console.log(err.response)
            })
        }
    }

    handleCheckClick = () => {
        this.setState({ checked: !this.state.checked });
    }


    handleToastClose = () => {
        this.setState({
            open: false
        })
        history.replace('/login');
    };

    render() {
        const { checked, submitted} = this.state;

        return (
            <div>
                <Snackbar open={this.state.open} autoHideDuration={6000} onClose={this.handleToastClose}>
                    <Alert onClose={this.handleToastClose} severity="success">
                        Your account has successfully been verified
                    </Alert>
                </Snackbar>
                <Box m={1} style={{padding: '5px'}}>
                    <h2>Verify Account</h2>
                    <form onSubmit={this.handleSubmit} autoComplete="off">
                        <div style={{display: "inline-flex", flexDirection: 'column', margin: '5px 0'}}>
                            <div>
                                I have read and agree to the <Link to='/termsandconditions'>Terms And Conditions</Link>
                            </div>
                            <FormLabel>
                                <Checkbox checked={this.state.checked} onChange={this.handleCheckClick}/> I agree
                            </FormLabel>
                            {submitted && !checked &&
                                <div className="help-block">Please read and accept terms</div>
                            }
                        </div>
                        <br/>
                        <div>
                            <Button type="submit" variant="contained">Verify Account</Button>
                        </div>
                    </form>
                </Box>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        profile: state.auth_reducer.profile,
        db_profile: state.auth_reducer.db_profile,
        is_authenticated: state.auth_reducer.is_authenticated
    }
}

function mapDispatchToProps(dispatch){
    return{
        is_authenticated: (is_authenticated) => dispatch(ACTIONS.login_success(is_authenticated))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
