

// Types of actions
export const SUCCESS = "SUCCESS"

export const FAILURE = "FAILURE"

export const USER_INPUT = "USER_INPUT"

export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const LOGIN_FAILURE = "LOGIN_FAILURE"

export const ADD_PROFILE = "ADD_PROFILE"

export const REMOVE_PROFILE = "REMOVE_PROFILE"

export const SET_DB_PROFILE = "SET_DB_PROFILE"

export const REMOVE_DB_PROFILE = "REMOVE_DB_PROFILE"

export const FETCH_DB_POSTS = "FETCH_DB_POSTS"

export const FETCH_DB_POST = "FETCH_DB_POST"

export const REMOVE_DB_POSTS = "REMOVE_DB_POSTS"

export const FETCH_POST_COMMENTS = "FETCH_POST_COMMENTS"

export const FETCH_POST_COMMENTS_SUCCESS = "FETCH_POST_COMMENTS_SUCCESS"

export const REMOVE_POST_COMMENTS = "REMOVE_POST_COMMENTS"

export const FETCH_USER_POSTS = "FETCH_USER_POSTS"

export const REMOVE_USER_POSTS = "REMOVE_USER_POSTS"

export const SEARCH_POSTS_SUCCESS = "SEARCH_POSTS_SUCCESS"

export const SEARCH_POSTS_FAILURE = "SEARCH_POSTS_FAILURE"

