import React, {Component} from "react";
import Button from "@material-ui/core/Button";
import history from "../utils/history";
import Avatar from "@material-ui/core/Avatar";
import {connect} from "react-redux";

export default function AvatarHeader (props) {

        return(
            <div className="profileSplash">
                <div className="profileBack"><Button style={{margin: 10}} variant="outlined" color="secondary"
                                                     onClick={() => history.replace('/posts/')}>&lt; Back</Button>
                </div>
                <div className="profileSplashImage">
                    <Avatar alt="Chosen avatar" src={props.props.currentUser.image}
                            style={{width: 100, height: 100}}/>
                    <div className="profileUserName">{props.props.currentUser.username}</div>
                </div>
            </div>
        )
}
