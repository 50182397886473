import React, { Component } from 'react';
import Routes from './routes';
import axios from 'axios';
import './App.css';


//main app 
class App extends Component {

  render() {
    return(

      <div>
        <Routes />
      </div>
    )
  }
}


export default App;
