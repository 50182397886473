import React, { Component } from 'react';
import axios from 'axios';

import history from '../utils/history';
import {TextField, Button, Paper} from '@material-ui/core/';
import {connect} from 'react-redux';
import ImageSelection from "../functional/imageSelection";
import Footer from "../functional/footer";


class AddPost extends Component {


    constructor(props) {
        super(props);

        this.state = {title: "", body: "", username: "", uid: 0, image: "", handleErrors: false, submitted: false};
        this.currentUser = {};

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    componentDidMount() {
        this.getSession();
    }

    getSession = (event) => {
        axios.get('//api.isogramconsulting.com/api/get/userprofile', {mode: "cors", credentials: 'include'})
            .then((res) => {
                this.currentUser =  res.data[0];
                this.setState({ username: this.currentUser.username, uid: this.currentUser.uid })
            }).catch((err) => {
                console.log(err);
                if(err.response.status===401 || err.response.status===403){
                window.location.href = '/';
            }})
    }

    SubmitData = async()=>{
        const data = this.state;
        let result = await axios.post('//api.isogramconsulting.com/api/post/poststodbWithSV', data, {mode: "cors", credentials: 'include'})
            .then(response => {return response;})
            .catch((err) => {
                console.log(err);
                if(err.response.status===401 || err.response.status===403){
                    window.location.href = '/';
                }
            })
    }

    handleSubmit = (event) => {
        event.preventDefault();

        console.log('adding post-->', this.state.title);

        if(this.state.title!==""&&this.state.body!==""){

            this.SubmitData(result=>{

            }).then(setTimeout(() => history.replace('/posts'), 700) )
        } else {
                this.setState({
                    handleErrors: true,
                    submitted: false
                })
        }
    }

    getSelection(selected){
        console.log("You selected=", selected)
    }


    render(){
        return(
            <div style={{padding: '20px'}}>
                <Button style={{marginBottom: '10px'}} onClick={()=> history.replace('/posts/')}>&lt; Back</Button>
                <Paper className="add-post-paper">
                    <h1>Add Post</h1>
                    <ImageSelection onSelectedImage={ e => this.state.image = e} />
                    <form onSubmit={this.handleSubmit}>
                        <br/>
                        You are welcome to display a <a href="http://www.vimeo.com/">vimeo</a> video instead of one of the default images as your post splash;<br/>
                        Extract only the numeric video id of your video and enter it here:<br/>
                        <TextField
                            variant="outlined"
                            autoFocus
                            type="number"
                            placeholder="508128216"
                            name='video'
                            label='Vimeo embed code'
                            margin='normal'
                            onChange={this.handleChange}
                            fullWidth
                        />
                        <TextField
                            variant="outlined"
                            autoFocus
                            name='title'
                            label='Title'
                            margin='normal'
                            onChange={this.handleChange}
                            fullWidth
                        />
                        {(this.state.handleErrors && this.state.title=="") &&
                            <div className="help-block">Title is required</div>
                        }
                        <br/>
                        <TextField
                            variant="outlined"
                            autoFocus
                            name='body'
                            label='body'
                            multiline
                            rows='4'
                            margin='normal'
                            onChange={this.handleChange}
                            fullWidth
                        />
                        {(this.state.handleErrors && this.state.body=="") &&
                            <div className="help-block">Please enter a body for your post</div>
                        }
                        <br/>
                        <Button type='submit'>Submit</Button>
                        <Button onClick={() => history.replace('/posts')}>Cancel</Button>
                </form>
                </Paper>
                <Footer />
            </div>
        )
    }
}


function mapStateToProps(state){
    return {
        db_profile: state.auth_reducer.db_profile
    }
}

export default connect(mapStateToProps)(AddPost)
