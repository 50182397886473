import imageQ from "../../images/avatar2.png";
import imageR from "../../images/avatar3.png";
import imageU from "../../images/avatar4.png";
import imageT from "../../images/avatar5.png";
import imageV from "../../images/avatar1.png";

export function setAvatar(data){
    var random;
    var gravatar = 'https://www.gravatar.com/avatar/';

    if (data[0].avatar_selection_code==="GR"){
        // random = gravatar+'c7b5e9a5dc30836777b6d4c0a78622ab';
        random = gravatar+data[0].gravatar;
    }

    if(data[0].avatar_selection_code==="AV"){
        let newImage = data[0].avatar;

        if(imageQ.toString().indexOf(newImage)>-1){
            random = imageQ
        }else if(imageR.toString().indexOf(newImage)>-1){
            random = imageR
        }else if(imageU.toString().indexOf(newImage)>-1) {
            random = imageU
        }else if(imageT.toString().indexOf(newImage)>-1) {
            random = imageT
        }else if(imageV.toString().indexOf(newImage)>-1) {
            random = imageV
        }
    }
    // console.log("in data utility avatar function", random);

    return random;
}
