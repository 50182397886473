import React from 'react'
import Signup from "../containers/Signup";
import LoginPage from "../containers/loginPage";
import {Button, Paper, Card} from "@material-ui/core/";
import Collapse from '@material-ui/core/Collapse';
import "../App.css"
import {Box} from "@material-ui/core";

//LoginSignup

export default function LoginSignup (props) {

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const [expanded, setExpanded] = React.useState(false);

    return (
        <div >
            <div className="hpg-wrapper">
                <div>
                    <div className="hpg-container">
                        <Signup />
                        <Button variant="outlined" color="primary" style={{padding: '0 20px 0 20px'}}
                            onClick={handleExpandClick}>Login
                        </Button>
                    </div>
            </div>
            </div>
            <div className="hpg-wrapper">
                <br/>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <LoginPage />
                </Collapse>
                <br/>
            </div>
        </div>
    )
}
