import React, { Component } from 'react';
import {connect} from 'react-redux';

import * as ACTIONS from '../store/actions/actions';

import axios from 'axios';
import {Box, Card, fade, InputBase, withStyles} from "@material-ui/core";
import {DataGrid, RowsProp, ColDef, SearchIcon} from '@material-ui/data-grid';
import history from "../utils/history";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import {formatDate} from "../utils/formatDate";

const drawerWidth = 240;
const navbarHeight = 55;
const footerHeight = 55;

const styles = (theme) => ({
    searchWrapper:{
        width: `calc(100vw - ${drawerWidth}px)`,
        backgroundColor: 'coral',
        padding: theme.spacing(1),
    },
    boxWrapper:{
        width: `calc(100vw - ${drawerWidth}px)`,
        height: `calc(100vh - ${navbarHeight}px - ${footerHeight}px)`
    },
    cardHeader:{
        textDecoration: 'underline'
    },
    searchbar:{
       margin: `calc(${theme.spacing(1)}px - 1px)`
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(3),
        width: '100%',
        marginLeft: 0,
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        width: '100%',
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    }
});


class Posts extends React.Component{

    data = [];
    currentUser = [];
    posts =  [];

    state = {
        data: this.data,
        currentUser: this.currentUser,
        posts: this.posts,
        searchString: ""
    };

    constructor(props) {
        super(props);

        this.handleSearch = this.handleSearch.bind(this);
    }


    handleRowSelection = (id) => {
        var rowId = parseInt(id[0]);
        if(this.state.data[rowId].uid === this.state.currentUser.uid){
            history.replace('/post/edit/' +this.state.data[rowId].pid);
        }else{
            history.replace('/post/view/' +this.state.data[rowId].pid);
        }
    }

    handleSearchPostSelection = (id) => {
        if(id === this.state.currentUser.uid){
            history.replace('/post/edit/' +id);
        }else{
            history.replace('/post/view/' +id);
        }
    }

    add_search_posts_to_state = (posts) => {
        this.setState({posts: []});
        this.setState({posts: [...posts]});
    }

    handlesearchtodb = async(search_query) => {
        try{
            let result = await axios.get('//api.isogramconsulting.com/api/get/searchposts', {withCredentials: true, params: {search_query: search_query}})
                return result;
        } catch (error){
            console.error(error);
        }

    }

    handleSearch = (e) =>{
        const search_query = e.target.value;
        this.handlesearchtodb(search_query).then(res => this.props.posts_success(res.data))
            .then(() => this.add_search_posts_to_state(this.props.search_posts));
    }

    logout(){
        axios.post('//api.isogramconsulting.com/api/post/logout', {}, {withCredentials: true}).then((res) => {
            console.log('you are logging out -->', res.data);
            if(res.data.loggedOut){
                history.replace('/');
            }
        }).catch((err) => console.log(err))
    }


    getData = async() =>{
        try {
            let result = [];
            let result1 = await axios.get('//api.isogramconsulting.com/api/get/allposts', {withCredentials:true}).catch((err) => {
                console.log(err);
                if(err.response.status===401 || err.response.status===403){
                    window.location.href = '/login';
                }
            });
            let result2 = await axios.get('//api.isogramconsulting.com/api/get/userprofile', {withCredentials:true}).catch((err) => {
                console.log(err);
                if(err.response.status===401 || err.response.status===403){
                    window.location.href = '/login';
                }
            });
            // console.log(result1.data, result2.data);
            result = {post: result1.data, currentUser: result2.data[0]};
            return result;
        } catch (e) {
            console.log(e);
        }
    }

    componentDidMount () {


            this.getData().then(result => {

                // console.log(result);

                    let res = result.post;
                    let currentUser = result.currentUser;

                    let data = [];

                    var j = 0
                    for (let i in res){

                        let ndate = formatDate(res[i]);

                        let post = {
                            id: j,
                            pid: res[i].pid,
                            title: res[i].title,
                            author: res[i].author,
                            date_created: ndate.toString(),
                            likes: (res[i].likes!==0?res[i].likes:null),
                            uid: res[i].user_id
                        };

                        data.push(post);
                        j++;
                    }

                    this.setState({
                        data: data,
                        currentUser: currentUser
                    })

            })
    }


render(){
    const { classes, theme } = this.props;

    const columns: ColDef[] = [
        { field: 'title', headerName: 'Title', width: 300 },
        { field: 'likes', width: 120, renderHeader: (params: ColParams) => (
                <strong>
                    <span role="img" aria-label="enjoy">
                        ♥
                    </span>
                    {' Likes '}
                    <span role="img" aria-label="enjoy">
                        ♥
                    </span>
                </strong>
            ), },
        { field: 'author', headerName: 'Author', width: 200 },
        { field: 'date_created', headerName: 'Date', width: 200},
    ];

    const rows: RowsProp[] = this.state.data ? this.state.data : null

    return(
            <div>
                <div className={classes.searchWrapper}>
                    <div className={classes.searchbar}>
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                placeholder="Search…"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                                autoFocus
                                name='search'
                                label='Search'
                                margin='none'
                                onChange={this.handleSearch}
                            />
                        </div>
                    </div>
                </div>
                {this.state.posts&&(<Box>
                    <div>
                        {this.state.posts.map((post, index)=>{
                            return (<Card key={index} className="searchCards">
                                <CardHeader
                                    title={post.title}
                                    onClick={()=>{this.handleSearchPostSelection(post.pid)}}
                                    subheader={"by " + post.author + " " + formatDate(post)}/>
                                <CardContent>
                                    {post.body.substr(0, 30)} <a href="#">Read More</a>
                                </CardContent>
                            </Card>)
                        })}
                    </div>
                </Box>)}
                <Box display="flex" className={classes.boxWrapper}>
                        <DataGrid
                            className="box-class-one"
                            rows={this.state.data}
                            columns={columns}
                            pageSize={10}
                            disableMultipleSelection={true}
                            disableClickEventBubbling={true}
                            onSelectionChange={(newSelection) => {
                                this.handleRowSelection(newSelection.rowIds);
                        }} />
                </Box>
            </div>
        )
}
}


function mapStateToProps(state) {
    return {
        profile: state.auth_reducer.profile,
        db_profile: state.auth_reducer.db_profile,
        is_authenticated: state.auth_reducer.is_authenticated,
        search_posts: state.posts_reducer.search_posts
    }
}

function mapDispatchToProps(dispatch){
    return{
        is_authenticated: (is_authenticated) => dispatch(ACTIONS.login_success(is_authenticated)),
        posts_success: (posts) => dispatch(ACTIONS.search_posts_success(posts)),
        posts_failure: () => dispatch(ACTIONS.remove_search_posts())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Posts));


