import React from 'react';

import { connect } from 'react-redux';
import * as ACTIONS from '../store/actions/actions';

import history from '../utils/history';
import {Box, Button, Card, Snackbar, TextField} from '@material-ui/core';
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import {Link} from "react-router-dom";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

axios.defaults.withCredentials = true;

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            openForgotDialog: false,
            username: '',
            password: '',
            resetEmail: '',
            submitted: false,
            resetSubmitted: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        // this.handleForgetPassword = this.handleForgetPassword(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true , loggedIn: false});
        const { username, password } = this.state;

        if (username && password) {
            //auth.login(username, password);

            axios.post('//api.isogramconsulting.com/api/post/login', {mode: "cors", credentials: 'include', params: {username: username, password: password}})
                .then((res) => {
                    if(res.data.confirmed){
                        history.replace('/posts');
                    }
                }).catch((err) => {
                    console.log(err)
                    console.log(err.response)
                    if(err.response.status===401 || err.response.status===403){
                        window.location.href = '/login';
                    }
                })
        }
    }

    handleForgetPassword = (e) => {
        e.preventDefault();

        this.setState({ resetSubmitted: true})
        const { resetEmail } = this.state;

        if (resetEmail){
        axios.post('//api.isogramconsulting.com/api/post/reset', {mode: "cors", credentials: 'include', params: {email: resetEmail}})
            .then((res) => {
                if(res.status===200){
                    this.setState({open: true})
                }
            }).catch((err) => {
            console.log(err)
            console.log(err.response)
            if(err.response.status===401 || err.response.status===403){
                window.location.href = '/login';
            }
        })
        }

    }

    handleCloseForgotDialog=()=>{
        this.setState({
            openForgotDialog: false
        })
    }

    handleToastClose = () => {
        this.setState({
            open: false
        })
    };

    render() {
        const { loggingIn, loggedIn} = this.props;
        const { username, password, submitted, resetEmail, resetSubmitted} = this.state;
        return (
            <div>
                <Snackbar open={this.state.open} autoHideDuration={6000} onClose={this.handleToastClose}>
                    <Alert onClose={this.handleToastClose} severity="success">
                       We sent a reset password link to your inbox
                    </Alert>
                </Snackbar>
                <Card style={{padding: '10px'}}>
                    <form onSubmit={this.handleSubmit} autoComplete="off" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <div style={{display: "inline-flex"}}>
                        <div>
                            <TextField name="username" label="Username" variant="outlined" onChange={this.handleChange} type="text"/>
                            {submitted && !username &&
                                <div className="help-block">Username is required</div>
                            }
                        </div>
                        <div style={{marginLeft: '5px'}}>
                            <TextField name="password" label="Password" variant="outlined" onChange={this.handleChange} type="password"/>
                            {submitted && !password &&
                                <div className="help-block">Password is required</div>
                            }
                        </div>
                        </div>
                        <br/>
                        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <Button type="submit" variant="contained">Login</Button>
                            <div style={{paddingTop: '8px', cursor: "pointer"}} onClick={()=>this.setState({openForgotDialog: true})}>Forgot Password?</div>
                        </div>
                    </form>
                </Card>

                <Dialog open={this.state.openForgotDialog} onClose={this.handleCloseForgotDialog} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Forgot Password</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please enter your verified email address:
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            name="resetEmail"
                            label="Email Address"
                            type="email"
                            onChange={this.handleChange}
                            fullWidth
                        />
                        {resetSubmitted && !resetEmail &&
                            <div className="help-block">Email is required</div>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseForgotDialog} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleForgetPassword} color="primary">
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        profile: state.auth_reducer.profile,
        db_profile: state.auth_reducer.db_profile,
        is_authenticated: state.auth_reducer.is_authenticated
    }
}

function mapDispatchToProps(dispatch){
    return{
        is_authenticated: (is_authenticated) => dispatch(ACTIONS.login_success(is_authenticated))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
