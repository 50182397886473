import imageDMapping from "../../dataMpping/postImageDataMapping.json";
import imageA from "../../images/randompostimage3.jpg";
import imageB from "../../images/randompostimage1.jpg";
import imageC from "../../images/randompostimage2.jpg";
import imageD from "../../images/randompostimage4.jpg";

export function setProfileSplash(data){
    let imageSetter;
    let newImage = data[0].image;

    imageDMapping.postimages.map(image => {
        if(image.id===newImage){
            if(imageA.toString().includes(image.value)){
                imageSetter = imageA
            }else if(imageB.toString().includes(image.value)){
                imageSetter = imageB
            }else if(imageC.toString().includes(image.value)) {
                imageSetter = imageC
            } else if(imageD.toString().includes(image.value)) {
                imageSetter = imageD
            }
        }
    })

    // console.log("in data utilities profile splash", imageSetter);
    return imageSetter;
}
