import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import imageMap from "../dataMpping/avatarImageMapping"

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Radio,
    RadioGroup,
    Snackbar,
    Box
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import axios from "axios";


export default function Signup() {
    const [open, setOpen] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [submitted, setSubmitted] = React.useState(false);
    const [handleErrors, setHandleErrors] = React.useState(false);
    const INIT_STATE = { username: "", password: "", confirmpw: "", email: "", avatar: []}
    const [selection, setSelection] = React.useState({
        value: "1"
    });
    const [myState, setMyState] = React.useState(INIT_STATE);
    const handleClickOpen = () => {
        setOpen(true);
    };


    const handleSignup = () => {

        console.log('handling signup->', myState.username);


        if(myState.username==""|| myState.email=="" || myState.password==""){
            setHandleErrors(true)
            setSubmitted(false)
        } else {
            setHandleErrors(false)
            setSubmitted(true)
        }

        if(submitted) {
            axios.post('//api.isogramconsulting.com/api/post/signup', {
                params: {
                    username: myState.username,
                    email: myState.email,
                    password: myState.password,
                    avatar: selection
                }
            }, {mode: "cors", credentials: 'include'}).then((res) => {
                    // console.log('confirmation', res.data)
                    if(res.data.success==="success"){
                        setSuccess(true);
                    }
            }).catch((err) => console.log(err)).finally(() => setOpen(false))
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleToastClose = () => {
        setSuccess(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setMyState(prevState => ({
            ...prevState,
            [name]: value
        }))
    };

    const updateSelection = (event, value) => {
        event.persist();
        const name = event.target.name;
        setSelection({ ...selection, [name]: value });

    };

    return (
        <div>
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
                Signup
            </Button>

            <Snackbar open={success} autoHideDuration={6000} onClose={handleToastClose}>
                <Alert onClose={handleToastClose} severity="success">
                    You have registered successfully
                </Alert>
            </Snackbar>

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Quick Signup</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Own and manage your content
                    </DialogContentText>
                    <div style={{minWidth: '400px'}}>
                        <h2>Profile Image</h2>
                            <div>
                                Choose from these available avatars<br/><br/>
                                <RadioGroup
                                    name="value"
                                    row
                                    value={selection.value}
                                    onChange={updateSelection}>
                                        {imageMap.images.map(image => (
                                            <Tooltip key={image.id} title={image.title}>
                                                <FormControlLabel
                                                    control={<Radio color="primary" />}
                                                    label={<Box className={"imagebox"+image.id} />}
                                                    key={image.id}
                                                    value={image.id}
                                                />
                                            </Tooltip>))}
                                </RadioGroup>
                            </div>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="username"
                        label="Username"
                        type="text"
                        onChange={handleChange}
                        value={myState.username}
                        fullWidth
                    />
                    {(handleErrors && myState.username=="") &&
                        <div className="help-block">Username is required</div>
                    }
                    <TextField
                        margin="dense"
                        name="email"
                        label="Email Address"
                        type="email"
                        onChange={handleChange}
                        value={myState.email}
                        fullWidth
                    />
                    {(handleErrors && myState.email=="") &&
                        <div className="help-block">Email is required</div>
                    }
                    <TextField
                        margin="dense"
                        name="password"
                        label="Password"
                        type="password"
                        value={myState.password}
                        onChange={handleChange}
                        fullWidth
                    />
                    {(handleErrors && myState.password=="") &&
                        <div className="help-block">Email is required</div>
                    }
                    <TextField
                        margin="dense"
                        name="confirmpw"
                        label="Confirm Password"
                        onChange={handleChange}
                        type="password"
                        value={myState.confirmpw}
                        fullWidth
                    />
                    {(myState.password!==myState.confirmpw) &&
                        <div className="help-block">Passwords must match</div>
                    }
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSignup} color="primary">
                        Signup
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
