import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LoginSignup from "../functional/loginSignup";
import {Avatar, Box, Card, TablePagination} from "@material-ui/core";
import DevicesIcon from '@material-ui/icons/Devices';
import BookIcon from '@material-ui/icons/Book';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import GitHubIcon from '@material-ui/icons/GitHub';
import {Link} from "react-router-dom";
import Footer from "../functional/footer";


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    dheadtext:{
        flexGrow:1,
        justifyContent: 'flex-start',
        marginLeft: 10
    },
    content: {
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    cardcontent : {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        flexGrow: 1,
        backgroundColor: "peachpuff",
        margin: theme.spacing(3),
        padding: theme.spacing(4, 1),
    },
    stickclass :{
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        margin: theme.spacing(4, 2),
        padding: theme.spacing(0, 5),
    },
    myAvIcon: {
        backgroundColor: '#3f51b5'
    }
}));

export default function PersistentDrawerLeft() {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, open && classes.hide)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        I am Isogram
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <Box className={classes.dheadtext}>HEC v1.0</Box>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                <Divider />
                <List>
                    <ListItem button onClick={()=>window.open("http://www.github.com/isogramc")}>
                        <ListItemIcon><GitHubIcon /></ListItemIcon>
                        <ListItemText primary={"About Us"} />
                    </ListItem>
                    <ListItem button onClick={()=>window.open("http://www.linktree.com/theresamostert")}>
                        <ListItemIcon><RecentActorsIcon /></ListItemIcon>
                        <ListItemText primary={"Contact Us"} />
                    </ListItem>
                </List>
                <Divider />
                <Box m={1} p={2}>&copy; Isogram Consulting 2021</Box>
            </Drawer>
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}
            >
                <div className={classes.drawerHeader} />
                <div className="draw-header" style={{width: '100%', paddingTop: '3em'}}>
                    <div className="rwc-login-signup">
                        <LoginSignup />
                    </div>
                </div>
                <div className="container1">
                    <div className="inner-container">
                        <div className="text-wrapp">
                            <span className="here-text">YOUR</span>
                                LOGO
                            <span className="here-text">HERE</span>
                        </div>
                        <br/><br/>
                        <div className="subheader"><strong>Welcome to your front row seat</strong></div>
                    </div>
                </div>
                <Typography variant="h6">
                    <Box p={5}>
                        <div className="sticker">HEC is a fully responsive Javascript website template built in React with Material components</div>
                    </Box>
                </Typography>
                    <div>
                        <div className="content2">
                            <Card className={classes.cardcontent}>
                                <div className={classes.stickclass}>
                                    <Avatar className={classes.myAvIcon}>
                                        <DevicesIcon />
                                    </Avatar>
                                </div>
                                <Box className={classes.stickclass}>Looks great on all your devices.</Box>
                            </Card>
                            <Card className={classes.cardcontent}>
                                <div className={classes.stickclass}>
                                    <Avatar className={classes.myAvIcon}>
                                        <BookIcon />
                                    </Avatar>
                                </div>
                                <div>
                                    <Box className={classes.stickclass}>Great alternative to other blog engines</Box>
                                </div>
                            </Card>
                            <Card className={classes.cardcontent}>
                                <div className={classes.stickclass}>
                                    <Avatar className={classes.myAvIcon}>
                                        <GitHubIcon />
                                    </Avatar>
                                </div>
                                <div>
                                    <Box className={classes.stickclass}>Easy deployments and updates.</Box>
                                </div>
                            </Card>
                            <Card className={classes.cardcontent}>
                                <div className={classes.stickclass}>
                                    <Avatar className={classes.myAvIcon}>
                                        <RecentActorsIcon />
                                    </Avatar>
                                </div>
                                <div>
                                    <Box className={classes.stickclass}>All your contacts and notifications in one place.</Box>
                                </div>
                            </Card>
                        </div>
                        <Footer />
                    </div>
            </main>
        </div>
    );
}
