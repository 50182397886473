export function formatDate(data){

    let day = new Date(data.date_created).getDate();
    let month = new Date(data.date_created).getMonth();
    let year = new Date(data.date_created).getFullYear();
    let hours = new Date(data.date_created).getUTCHours();
    let minutes = new Date(data.date_created).getUTCMinutes();
    let ampm = (hours >= 12) ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0'+ minutes : minutes;
    let ndate = day + '/' +  (month+1) + '/' + year + ' | ' + hours + ':' + minutes + ampm;

    return ndate;
}
