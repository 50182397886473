import React from 'react'
import {Box, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import postImageMap from "../dataMpping/postImageDataMapping.json";
import Tooltip from "@material-ui/core/Tooltip";


export default function ImageSelection (props: props) {

    const [selection, setSelection] = React.useState({
        value: "1"
    });

    const updateSelection = (event, value) => {
        event.persist();
        const name = event.target.name;
        setSelection({ ...selection, [name]: value });
    };


    return (
    <div>
        Choose a cover image <br/>
        <div style={{color: "white"}}>{selection.value?props.onSelectedImage(selection.value):null}</div>
        <RadioGroup
            name="value"
            row
            value={selection.value}
            onChange={updateSelection}>
            {postImageMap.postimages.map(image => (
                <Tooltip key={image.id} title={image.title}>
                    <FormControlLabel
                        control={<Radio color="primary" />}
                        label={<Box className={"postimage"+image.id} />}
                        key={image.id}
                        value={image.id}
                    />
                </Tooltip>))}
        </RadioGroup>
    </div>
    )
}
