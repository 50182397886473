import React, { Component } from 'react';
import axios from 'axios';

import history from '../utils/history';
import {TextField, Button, Paper} from '@material-ui/core/';
import {connect} from 'react-redux';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Fab from "@material-ui/core/Fab";
import {Card} from "./showpost";
import {green} from "@material-ui/core/colors";
import AddCommentIcon from "@material-ui/icons/AddComment";
import {Snackbar} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";


class AddComment extends Component {

    constructor(props) {
        super(props);

        this.state = {open: false, success: false, comment: "", parentPath:{ post_id: this.props.parentpath.id}, currentUser: []};

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleToastClose = () => {
        this.setState({ success: false });
    };

    componentDidMount() {
        this.getSession();
    }

    getSession = (event) => {
        axios.get('//api.isogramconsulting.com/api/get/userprofile', {withCredentials:true})
            .then((res) => {
                this.currentUser =  res.data[0];
                this.setState({ currentUser: { username: this.currentUser.username, uid: this.currentUser.uid }})
            }).catch((err) => {
                console.log(err);
                if(err.response.status===401 || err.response.status===403){
                window.location.href = '/';
            }})
    }

    handleSubmit = (event) => {

        let post_id = this.state.parentPath.post_id;

        let data = {
            comment: this.state.comment,
            post_id: post_id,
            author: this.state.currentUser.username,
            user_id: this.state.currentUser.uid
        };

        axios.post('//api.isogramconsulting.com/api/post/commenttodb', data, {withCredentials: true})
            .then(response => {
                console.log(response.status);
                if(response.status==200){
                    this.setState({success: true});
                    this.reloadOrNavigate();
                }
            }).catch((err) => {
                console.log(err);
                if(err.response.status===401 || err.response.status===403){
                    window.location.href = '/';
                }
            })
    }

    reloadOrNavigate = () => {
        const { history, location } = this.props;
        const curLocation = '/post/view/' + this.props.parentpath.id
        const inEdit = '/post/edit/' + this.props.parentpath.id
        const locationId = this.props.parentpath.id
        if (window.location.pathname === (curLocation)) {
            window.location.href = '/post/view/' + locationId;
        } else if(window.location.pathname === (inEdit)){
            window.location.href = '/post/edit/' + locationId;
        }
    };

    render(){
        return(
            <div>
                <Snackbar open={this.state.success} autoHideDuration={6000} onClose={this.handleToastClose}>
                    <Alert onClose={this.handleToastClose} severity="success">
                        Your comment has been added successfully
                    </Alert>
                </Snackbar>

                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'

                >
                    <DialogTitle id='alert-dialog-title'> Add Comment </DialogTitle>
                    <DialogContent>
                        <TextField
                            variant="outlined"
                            name='comment'
                            multiline
                            rows='4'
                            margin='normal'
                            onChange={this.handleChange}
                            value={this.state.comment}
                            fullWidth
                        />
                        <DialogActions>
                            <Button onClick={() => {this.handleSubmit(); this.setState({open: false})}}>
                                Confirm
                            </Button>
                            <Button onClick={() => this.setState({open: false})}>
                                Cancel
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
                <div className="add-comment-button">
                    <Fab color="default" aria-label="add" onClick={() => this.setState({open: true})}>
                        <AddCommentIcon style={{ color: green[500] }} />
                    </Fab>
                </div>
            </div>
        )
    }
}


function mapStateToProps(state){
    return {
        db_profile: state.auth_reducer.db_profile
    }
}

export default connect(mapStateToProps)(AddComment)
